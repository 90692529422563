import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'
import mixins from '@core/mixins/mixins'

import { ValidationObserver, ValidationProvider, extend, localize } from 'vee-validate';

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'


// 3rd party plugins
import '@axios'
import '@core/libs/sokets'
// import '@/libs/clipboard'
// import '@/libs/sweet-alerts'

import {onRejected} from '@/libs/axios/interceptors'
// import '@/libs/tour'


// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard

// import core styles
// require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

// Vue.prototype.$http.interceptors.response.use(undefined, (error) => onRejected(error, store, router))

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);


Vue.mixin(mixins);

new Vue({
	router,
	store,
	i18n,
	render: h => h(App),
}).$mount('#app')