<template>
  <div class="contents">
    <div class="bg-blue-300 w-full h-2"/>
    <div class="bg-yellow-300 h-2 w-full"/>
    <div class="container mx-auto flex flex-col">
      <!-- ... -->
      <div class="flex flex-row text-black w-full my-4 px-2 sm:px-2">
        <div class="font-bold text-lg"><router-link :to="{name: 'home'}">DonorDeck.org</router-link></div>
        <div class="flex flex-grow items-center justify-end font-bold text-sm letters-wider space-x-2">
          <div class="text-black">

            <a href="#" class="border-b border-dashed-2 border-b-black hover:text-red-500"
               @click.prevent="modal = 'add'">Додати банку</a>
          </div>
          <div class="text-black">
            <a href="#" class="border-b ml-1 border-dashed-2 border-b-black hover:text-red-500"
               @click.prevent="modal = 'contacts'">Контакти</a>
          </div>

        </div>
      </div>
    </div>
    <div class="container mx-auto flex flex-col">
      <Modal v-if="modal === 'add'" @close="modal=null">
        <template #header>Як добавити банку</template>
        <template #body>
          Для того, щоб додати банку на DonorDeck.org напишіть в телеграмм <a href="https://t.me/devphp9" class="underline" target="_blank">@devphp9</a> <br/>
          Будьте готові додати докази збору.
          <br/>
          <br/>


        </template>
      </Modal>
      <Modal v-if="modal === 'contacts'" @close="modal=null">
        <template #header>Контакти</template>
        <template #body>
           Телеграм  <a href="https://t.me/devphp9" class="underline" target="_blank">@devphp9</a>
          <br/>
          <br/>


        </template>
      </Modal>

      <router-view/>
    </div>

  </div>


</template>

<script>


import Modal from "@/views/Modal.vue";

export default {
  components: {Modal},
  data() {
    return {
      modal: null
    }
  },
  setup() {

  },
}
</script>

<style>
body {
  background: red;
}
</style>
