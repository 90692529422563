import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import middleware from './middlewares/middleware'
import AuthRoutes from "@/router/routes/AuthRoutes";


Vue.use(VueRouter)


const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return {x: 0, y: 0}
	},
	routes: [
		...AuthRoutes,
	],
})

router.beforeEach(middleware({store}))

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg')
	if (appLoading) {
		appLoading.style.display = 'none'
	}
})

export default router
