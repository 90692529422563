<script>
export default {
  name: "Modal"
}
</script>

<template>
  <transition name="modal">
    <div class="shadow rounded bg-slate-300  p-4 mb-4">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header font-bold text-lg">
            <slot name="header">
            </slot>
          </div>

          <div class="modal-body text-sm">
            <slot name="body">

            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button class="modal-default-button bg-black text-white rounded p-2 px-4 text-xs" @click="$emit('close')">
                Закрити
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<style >


/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>