import ensureCsrfTokenSet from "@/router/middlewares/ensureCsrfTokenSet";
import guest from "@/router/middlewares/guest";

export default [
	{
		path: '/jar/:jar',
		name: 'jar',
		component: () => import('@/views/jar/JarView.vue'),
		meta: {
			layout: 'vertical',
			middleware: [guest],
			title: 'Login'
		},
	},
	{
		path: '/',
		name: 'home',
		component: () => import('@/views/Home.vue'),
		meta: {
			layout: 'vertical',
			middleware: [guest],
		},
	},
];