export default {
	"message": {
		"title": "Card Title",
		"text": "Cake sesame snaps cupcake gingerbread danish I love gingerbread. Apple pie pie jujubes chupa chups muffin halvah lollipop. Chocolate cake oat cake tiramisu marzipan sugar plum. Donut sweet pie oat cake dragée fruitcake cotton candy lemon drops.",
		"pagelength": "Showing 1 to",
		"of": "of",
		"pageText2": "entries",
		"seachLabel": "Search",
		"SearchPlaceholder": "Search",
		"tableHeader": {
			"name": "Name",
			"email": "Email",
			"date": "Date",
			"salary": "Salary",
			"status": "Status",
			"action": "Action"
		}
	},
	"UI Elements": "UI Elements",
	"Forms & Tables": "Forms & Tables",
	"Pages": "Pages",
	"Charts & Maps": "Charts & Maps",
	"Others": "Others",
	"Typography": "Typography",
	"Colors": "Colors",
	"Feather": "Feather",
	"Cards": "Cards",
	"Basic": "Basic",
	"Advance": "Advance",
	"Statistic": "Statistic",
	"Analytic": "Analytic",
	"Card Action": "Card Action",
	"Components": "Components",
	"Alert": "Alert",
	"Aspect": "Aspect",
	"Avatar": "Avatar",
	"Badge": "Badge",
	"Breadcrumb": "Breadcrumb",
	"Button": "Button",
	"Button Group": "Button Group",
	"Button Toolbar": "Button Toolbar",
	"Calendar": "Calendar",
	"Carousel": "Carousel",
	"Collapse": "Collapse",
	"Dropdown": "Dropdown",
	"Embed": "Embed",
	"Image": "Image",
	"List Group": "List Group",
	"Media": "Media",
	"Modal": "Modal",
	"Nav": "Nav",
	"Overlay": "Overlay",
	"Pagination": "Pagination",
	"Pagination Nav": "Pagination Nav",
	"Pill": "Pill",
	"Pill Badge": "Pill Badge",
	"Popover": "Popover",
	"Progress": "Progress",
	"Sidebar": "Sidebar",
	"spinner": "spinner",
	"Tab": "Tab",
	"Time": "Time",
	"Toasts": "Toasts",
	"Tooltip": "Tooltip",
	"Extensions": "Extensions",
	"Sweet Alert": "Sweet Alert",
	"Quill Editor": "Quill Editor",
	"Drag & Drop": "Drag & Drop",
	"Swiper": "Swiper",
	"Clipboard": "Clipboard",
	"Video Player": "Video Player",
	"Context Menu": "Context Menu",
	"Toastification": "Toastification",
	"I18n": "I18n",
	"Slider": "Slider",
	"Tour": "Tour",
	"Auto Suggest": "Auto Suggest",
	"Tree": "Tree",
	"Date Time Picker": "Date Time Picker",
	"Vue Select": "Vue Select",
	"Forms Elements": "Forms elements",
	"Select": "Select",
	"Switch": "Switch",
	"Checkbox": "Checkbox",
	"Radio": "Radio",
	"Input": "Input",
	"Textarea": "Textarea",
	"Spinbutton": "Spinbutton",
	"Input Group": "Input Group",
	"Form Rating": "Form Rating",
	"Form Tag": "Form Tag",
	"Form Datepicker": "Form Datepicker",
	"Form Timepicker": "Form Timepicker",
	"File Input": "File Input",
	"Input Mask": "Input Mask",
	"Form Layout": "Form Layout",
	"Form Wizard": "Form Wizard",
	"Form Validation": "Form Validation",
	"Form Repeater": "Form Repeater",
	"BS Table": "BS Table",
	"Good Table": "Good Table",
	"Charts": "Charts",
	"Apex Chart": "Apex Chart",
	"Chartjs": "Chartjs",
	"Echart": "Echart",
	"Leaflet": "Leaflet",
	"Profile": "Profile",
	"Account Settings": "Account Settings",
	"Faq": "Faq",
	"Knowledge Base": "Knowledge Base",
	"Pricing": "Pricing",
	"Blog": "Blog",
	"List": "List",
	"Detail": "Detail",
	"Edit": "Edit",
	"Search": "Search",
	"Menu Levels": "Menu Levels",
	"Menu Level 2.1": "Menu Level 2.1",
	"Menu Level 2.2": "Menu Level 2.2",
	"Menu Level 3.1": "Menu Level 3.1",
	"Menu Level 3.2": "Menu Level 3.2",
	"Disabled Menu": "Disabled Menu",
	"Support": "Support",
	"Raise Support": "Raise Support",
	"Documentation": "Documentation",
	"Dashboards": "Dashboards",
	"eCommerce": "eCommerce",
	"Analytics": "Analytics",
	"Apps & Pages": "Apps & Pages",
	"Email": "Email",
	"Chat": "Chat",
	"Todo": "Todo",
	"Invoice": "Invoice",
	"Preview": "Preview",
	"Add": "Add",
	"Shop": "Shop",
	"Details": "Details",
	"Wishlist": "Wishlist",
	"Checkout": "Checkout",
	"User": "User",
	"View": "View",
	"Authentication": "Authentication",
	"Login v1": "Login v1",
	"Login v2": "Login v2",
	"Register v1": "Register v1",
	"Register v2": "Register v2",
	"Forget Password v1": "Forget Password v1",
	"Forget Password v2": "Forget Password v2",
	"Forgot Password v1": "Forgot Password v1",
	"Forgot Password v2": "Forgot Password v2",
	"Reset Password v1": "Reset Password v1",
	"Reset Password v2": "Reset Password v2",
	"Miscellaneous": "Miscellaneous",
	"Coming Soon": "Coming Soon",
	"Not Authorized": "Not Authorized",
	"Under Maintenance": "Under Maintenance",
	"Error": "Error",
	"Statistics": "Statistics",
	"Card Actions": "Card Actions",
	"Media Objects": "Media Objects",
	"Timeline": "Timeline",
	"Access Control": "Access Control",
	"Apps": "Apps",
	"User Interface": "User Interface",
	"Mail Templates": "Mail Templates",
	"Welcome": "Welcome",
	"Reset Password": "Reset Password",
	"Reset": "Reset",
	"Verify Email": "Verify Email",
	"Deactivate Account": "Deactivate Account",
	"Promotional": "Promotional",
	"Page Layouts": "Page Layouts",
	"Collapsed Menu": "Collapsed Menu",
	"Layout Boxed": "Layout Boxed",
	"Without Menu": "Without Menu",
	"Layout Empty": "Layout Empty",
	"Layout Blank": "Layout Blank",
	"Success": "Success",
	"Successfully logged in": "Successfully logged in",
	"Name": "Name",
	"Username": "Username",
	"Mobile": "Mobile",
	"Role": "Role",
	"Password": "Password",
	"Password Confirmation": "Password Confirmation",
	"Save": "Save",
	"New Password": "New Password",
	"Retype New Password": "Retype New Password",
	"Is Active": "Is Active?",
	"Delete": "Delete",
	"Home": "Home",
	"Cancel": "Cancel",
	"Loading": "Loading...",
	"Login": "Login",
	"Forget Password": "Forget Password",
	"Choose a date": "Choose a date",
	"From Date": "From Date",
	"To Date": "To Date",
	"Please Confirm": "Please Confirm",
	"Permissions": "Permissions",
	"Drop File": "Drop file here or <span class='filepond--label-action'>Browse</span>",
	model_filters: (ctx) => `${ctx.named('model')} Filters:`,
	add_model: (ctx) => `Add ${ctx.named('model')}`,
	update_model: (ctx) => `Update ${ctx.named('model')}`,
	model_details: (ctx) => `${ctx.named('model')} Details`,
	model_statistics: (ctx) => `${ctx.named('model')} Statistics`,
	model_chart: (ctx) => `${ctx.named('model')} Chart`,
	save_model: (ctx) => `Save ${ctx.named('model')}`,
	import_model: (ctx) => `Import ${ctx.named('model')}`,
	field_lang: (ctx) => `${ctx.named('field')} in ${ctx.named('lang')}`,
	delete_confirmation: "Please confirm that you want to delete this resource.",
	select_all: 'Select All',
	unselect: 'Un Select',
	modules: {
		users: {
			create: 'Create User',
			edit: 'Edit User',
			details: 'User Details',
			users: 'Users',
			user: 'User',
		},
		roles: {
			create: 'Create Role',
			edit: 'Edit Role',
			details: 'Role Details',
			roles: 'Roles',
			role: 'Role',
		}
	},
	active_status_list: [
		{label: 'Active', value: 'active'},
		{label: 'Inactive', value: 'inactive'},
	]
};
